import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuGroup, DropdownMenuItem, Icon, IconStatusStarFilled, IconActionVisibilityOn } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropdownmenu"
    }}>{`DropdownMenu`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { DropdownMenu, DropdownMenuDivider, DropdownMenuGroup, DropdownMenuItem } from '@hse-design/react'
`}</code></pre>
    <ComponentLinks figma={"https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=582%3A2499"} storybook={"/?path=/story/dropdown-dropdownmenu--playground"} vue={"/components/HseDropdownMenu/HseDropdownMenu.html"} vueStorybook={"/?path=/story/dropdown-dropdownmenu--playground"} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`DropdownMenu – это компонент, который используется для предоставления пользователю списка опций. В качестве опций следует использовать компонент DropdownMenuItem.`}</p>
    <p>{`Для объединения элементов в группы используйте компонент DropdownMenuGroup. Для разделения групп опций используйте DropdownMenuDivider.`}</p>
    <p>{`DropdownMenu используется в компоненте `}<a parentName="p" {...{
        "href": "/src-components-select"
      }}>{`Select`}</a>{`.`}</p>
    <Playground __position={1} __code={'<DropdownMenu header={\'Header\'}>\n  <DropdownMenuItem badge={10} leftIcon={IconStatusStarFilled}>\n    Icon Badge\n  </DropdownMenuItem>\n  <DropdownMenuItem selected badge={10} leftIcon={IconStatusStarFilled}>\n    Hovered Icon Badge\n  </DropdownMenuItem>\n  <DropdownMenuItem selected badge={10} leftIcon={IconStatusStarFilled}>\n    Selected Icon Badge\n  </DropdownMenuItem>\n  <DropdownMenuItem\n    selected\n    hovered\n    badge={10}\n    leftIcon={IconStatusStarFilled}\n  >\n    Selected Hovered Icon Badge\n  </DropdownMenuItem>\n  <DropdownMenuItem selected>Selected</DropdownMenuItem>\n  <DropdownMenuItem selected hovered>\n    Selected Hovered\n  </DropdownMenuItem>\n  <DropdownMenuItem hovered>Hovered</DropdownMenuItem>\n  <DropdownMenuDivider />\n  <DropdownMenuGroup title=\"Group name\">\n    <DropdownMenuItem\n      left={<Icon icon={IconStatusStarFilled} style={{ color: \'#6a7a98\' }} />}\n      right={\n        <Icon icon={IconActionVisibilityOn} style={{ color: \'#6a7a98\' }} />\n      }\n    >\n      Item 1\n    </DropdownMenuItem>\n    <DropdownMenuItem>Item 2</DropdownMenuItem>\n    <DropdownMenuItem>Item 3</DropdownMenuItem>\n    <DropdownMenuItem>Item 4</DropdownMenuItem>\n  </DropdownMenuGroup>\n  <DropdownMenuItem disabled>Item 1</DropdownMenuItem>\n  <DropdownMenuItem>Item 2</DropdownMenuItem>\n  <DropdownMenuItem>Item 3</DropdownMenuItem>\n  <DropdownMenuItem disabled>Item 4</DropdownMenuItem>\n</DropdownMenu>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      DropdownMenu,
      DropdownMenuDivider,
      DropdownMenuGroup,
      DropdownMenuItem,
      Icon,
      IconStatusStarFilled,
      IconActionVisibilityOn,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <DropdownMenu header={'Header'} mdxType="DropdownMenu">
        <DropdownMenuItem badge={10} leftIcon={IconStatusStarFilled} mdxType="DropdownMenuItem">
          Icon Badge
        </DropdownMenuItem>
        <DropdownMenuItem selected badge={10} leftIcon={IconStatusStarFilled} mdxType="DropdownMenuItem">
          Hovered Icon Badge
        </DropdownMenuItem>
        <DropdownMenuItem selected badge={10} leftIcon={IconStatusStarFilled} mdxType="DropdownMenuItem">
          Selected Icon Badge
        </DropdownMenuItem>
        <DropdownMenuItem selected hovered badge={10} leftIcon={IconStatusStarFilled} mdxType="DropdownMenuItem">
          Selected Hovered Icon Badge
        </DropdownMenuItem>
        <DropdownMenuItem selected mdxType="DropdownMenuItem">Selected</DropdownMenuItem>
        <DropdownMenuItem selected hovered mdxType="DropdownMenuItem">
          Selected Hovered
        </DropdownMenuItem>
        <DropdownMenuItem hovered mdxType="DropdownMenuItem">Hovered</DropdownMenuItem>
        <DropdownMenuDivider mdxType="DropdownMenuDivider" />
        <DropdownMenuGroup title="Group name" mdxType="DropdownMenuGroup">
          <DropdownMenuItem left={<Icon icon={IconStatusStarFilled} style={{
            color: '#6a7a98'
          }} mdxType="Icon" />} right={<Icon icon={IconActionVisibilityOn} style={{
            color: '#6a7a98'
          }} mdxType="Icon" />} mdxType="DropdownMenuItem">
            Item 1
          </DropdownMenuItem>
          <DropdownMenuItem mdxType="DropdownMenuItem">Item 2</DropdownMenuItem>
          <DropdownMenuItem mdxType="DropdownMenuItem">Item 3</DropdownMenuItem>
          <DropdownMenuItem mdxType="DropdownMenuItem">Item 4</DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuItem disabled mdxType="DropdownMenuItem">Item 1</DropdownMenuItem>
        <DropdownMenuItem mdxType="DropdownMenuItem">Item 2</DropdownMenuItem>
        <DropdownMenuItem mdxType="DropdownMenuItem">Item 3</DropdownMenuItem>
        <DropdownMenuItem disabled mdxType="DropdownMenuItem">Item 4</DropdownMenuItem>
  </DropdownMenu>
    </Playground>
    <h2 {...{
      "id": "пропы-dropdownmenu"
    }}>{`Пропы DropdownMenu`}</h2>
    <Props of={DropdownMenu} mdxType="Props" />
    <h2 {...{
      "id": "пропы-dropdownmenuitem"
    }}>{`Пропы DropdownMenuItem`}</h2>
    <Props of={DropdownMenuItem} mdxType="Props" />
    <h2 {...{
      "id": "пропы-dropdownmenugroup"
    }}>{`Пропы DropdownMenuGroup`}</h2>
    <Props of={DropdownMenuGroup} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      